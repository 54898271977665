.button {
  --button-fill-background-color: none;
  --button-fill-color: none;

  --button-outline-background-color: transparent;
  --button-outline-border-color: none;
  --button-outline-color: none;

  --button-text-background-color: transparent;
  --button-text-color: none;


  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  border: 1px solid transparent;
  border-radius: 5px;

  transition-property: background-color, color;
  transition-duration: .3s;

  cursor: pointer;
  text-decoration: none;
  font-family: var(--font-primary);
  &_fill {
    background: var(--button-fill-background-color);
    color: var(--button-fill-color);
  }

  &_outline {
    border: 1px solid var(--button-outline-border-color);
    background-color: var(--button-outline-background-color);
    color: var(--button-outline-color);
  }

  &_text {
    background: var(--button-text-background-color);
    color: var(--button-text-color);
  }

  &_disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &_color {
    &_white {
      --button-fill-background-color: var(--white-1);
      --button-fill-color: var(--black-1);

      --button-outline-border-color: var(--white-1);
      --button-outline-color: var(--white-1);

      --button-text-background-color: transparent;
      --button-text-color: var(--white-1);

      &:hover {
        --button-fill-background-color: var(--white-2);
        --button-outline-background-color: var(--white-10);
        --button-text-background-color: var(--white-10);
      }

      &:active {
        --button-fill-background-color: var(--white-3);
        --button-outline-background-color: var(--white-20);
        --button-text-background-color: var(--white-20);
      }

      &_disabled {
        --button-fill-background-color: var(--white-50);
        --button-fill-color: var(--black-20);

        --button-outline-border-color: var(--white-20);
        --button-outline-color: var(--white-50);

        --button-text-color: var(--white-50);
      }
    }
    &_gray {
      --button-fill-background-color: var(--gray-1);
      --button-fill-color: var(--black-1);

      --button-outline-border-color: var(--gray-1);
      --button-outline-color: var(--gray-1);

      --button-text-background-color: transparent;
      --button-text-color: var(--gray-1);

      &:hover {
          --button-fill-background-color: var(--gray-2);
          --button-outline-background-color: var(--gray-10);
          --button-text-background-color: var(--gray-10);
      }

      &:active {
          --button-fill-background-color: var(--gray-3);
          --button-outline-background-color: var(--gray-20);
          --button-text-background-color: var(--gray-20);
      }

      &_disabled {
          --button-fill-background-color: var(--gray-50);
          --button-fill-color: var(--black-20);

          --button-outline-border-color: var(--gray-50);
          --button-outline-color: var(--gray-50);

          --button-text-color: var(--gray-50);
      }
    }
    &_blue {
      --button-fill-background-color: var(--blue-1);
      --button-fill-color: var(--white-1);

      --button-outline-border-color: var(--blue-1);
      --button-outline-color: var(--blue-1);

      --button-text-background-color: transparent;
      --button-text-color: var(--blue-1);

      &:hover {
        --button-fill-background-color: var(--blue-2);
        --button-outline-background-color: var(--blue-10);
        --button-text-background-color: var(--blue-10);
      }

      &:active {
        --button-fill-background-color: var(--blue-3);
        --button-outline-background-color: var(--blue-20);
        --button-text-background-color: var(--blue-20);
      }

      &_disabled {
        --button-fill-background-color: var(--blue-10);
        --button-fill-color: var(--blue-50);

        --button-outline-border-color: var(--blue-20);
        --button-outline-color: var(--blue-50);

        --button-text-color: var(--blue-50);
      }
    }

    &_success {
      --button-fill-background-color: var(--green-1);
      --button-fill-color: var(--white-1);

      --button-outline-border-color: var(--green-1);
      --button-outline-color: var(--green-1);

      --button-text-background-color: transparent;
      --button-text-color: var(--green-1);

      &:hover {
          --button-fill-background-color: var(--green-2);
          --button-outline-background-color: var(--green-10);
          --button-text-background-color: var(--green-10);
      }

      &:active {
          --button-fill-background-color: var(--green-3);
          --button-outline-background-color: var(--green-20);
          --button-text-background-color: var(--green-20);
      }

      &_disabled {
          --button-fill-background-color: var(--green-10);
          --button-fill-color: var(--green-50);

          --button-outline-border-color: var(--green-20);
          --button-outline-color: var(--green-50);

          --button-text-color: var(--green-50);
      }
    }
    &_danger {
      --button-fill-background-color: var(--red-1);
      --button-fill-color: var(--white-1);

      --button-outline-border-color: var(--red-1);
      --button-outline-color: var(--red-1);

      --button-text-background-color: transparent;
      --button-text-color: var(--red-1);

      &:hover {
          --button-fill-background-color: var(--red-2);
          --button-outline-background-color: var(--red-10);
          --button-text-background-color: var(--red-10);
      }

      &:active {
          --button-fill-background-color: var(--red-3);
          --button-outline-background-color: var(--red-20);
          --button-text-background-color: var(--red-20);
      }

      &_disabled {
          --button-fill-background-color: var(--red-10);
          --button-fill-color: var(--red-50);

          --button-outline-border-color: var(--red-20);
          --button-outline-color: var(--red-50);

          --button-text-color: var(--red-50);
      }
    }
    &_warning {
      --button-fill-background-color: var(--yellow-1);
      --button-fill-color: var(--black-1);

      --button-outline-border-color: var(--yellow-1);
      --button-outline-color: var(--yellow-3);

      --button-text-background-color: transparent;
      --button-text-color: var(--yellow-3);

      &:hover {
          --button-fill-background-color: var(--yellow-2);
          --button-outline-background-color: var(--yellow-10);
          --button-text-background-color: var(--yellow-10);
      }

      &:active {
          --button-fill-background-color: var(--yellow-3);
          --button-outline-background-color: var(--yellow-20);
          --button-text-background-color: var(--yellow-20);
      }

      &_disabled {
          --button-fill-background-color: var(--yellow-50);
          --button-fill-color: var(--yellow-3);

          --button-outline-border-color: var(--yellow-50);
        --button-outline-color: var(--yellow-2);

          --button-text-color: var(--yellow-2);
      }
    }
  }

  &_size {
    &_large {
      padding: 15px;
      font-size: 20px;
    }
    &_medium {
      padding: 8px;
      font-size: 18px;
    }
    &_small {
      padding: 8px;
      font-size: 16px;
    }
  }
}