.headline {
  font-family: var(--font-secondary);
  font-weight: 600;
  margin: 0;

  &_size {
    &_xl {
      font-size: clamp(32px, 5vw, 48px);
      font-weight: 900;
    }
    &_lg {
      font-size: clamp(28px, 5vw, 40px);
      font-weight: 700;
    }
    &_rg {
      font-size: clamp(24px, 5vw, 36px);
    }
    &_sm {
      font-size: clamp(20px, 5vw, 32px);
    }
    &_xs {
      font-size: clamp(16px, 5vw, 28px);
    }
    &_xxs {
      font-size: clamp(12px, 5vw, 24px);
    }
  }
}