@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=IBM+Plex+Sans:wght@400;500&family=Montserrat:wght@700;900&display=swap');
@import "base";

* {
  box-sizing: border-box;

  margin: 0;
  padding: 0;

  outline: 0;
  border: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-primary);
  font-size: 18px;

  color: var(--black-1);
  background-color: var(--white-1);

  overflow-x: hidden;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

#root {
  display: flex;
  flex-direction: column;

  flex: 1 1 100%;

  height: 100%;
  min-height: 100vh;
}