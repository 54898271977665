:root {
  --font-primary: "IBM Plex Sans", sans-serif;
  --font-secondary: "Montserrat", sans-serif;
  --font-code: "IBM Plex Mono", monospace;

  --container-width: 1400px;
  --section-spacing: 128px;

  --white-1: #FFFFFF;
  --white-2: #F5F5F5;
  --white-3: #EEEEEE;
  --white-10: rgba(255, 255, 255, 0.1);
  --white-20: rgba(255, 255, 255, 0.2);
  --white-50: rgba(255, 255, 255, 0.5);

  --gray-1: #DDDDDD;
  --gray-2: #CCCCCC;
  --gray-3: #B0B0B0;
  --gray-10: rgba(221, 221, 221, 0.1);
  --gray-20: rgba(221, 221, 221, 0.2);
  --gray-50: rgba(221, 221, 221, 0.5);

  --black-1: #000000;
  --black-2: #252525;
  --black-3: #333333;
  --black-10: rgba(0, 0, 0, 0.1);
  --black-20: rgba(0, 0, 0, 0.2);
  --black-50: rgba(0, 0, 0, 0.5);

  --red-1: #E02E43;
  --red-2: #D8263B;
  --red-3: #C8162B;
  --red-10: rgba(224, 46, 67, 0.1);
  --red-20: rgba(224, 46, 67, 0.2);
  --red-50: rgba(224, 46, 67, 0.5);

  --orange-1: #FD5E3B;
  --orange-2: #D01E33;
  --orange-3: #DB3C19;
  --orange-10: rgba(253, 94, 59, 0.1);
  --orange-20: rgba(253, 94, 59, 0.2);
  --orange-50: rgba(253, 94, 59, 0.5);

  --yellow-1: #FFEE54;
  --yellow-2: #F5E44A;
  --yellow-3: #E4D339;
  --yellow-10: rgba(255, 238, 84, 0.1);
  --yellow-20: rgba(255, 238, 84, 0.2);
  --yellow-50: rgba(255, 238, 84, 0.5);

  --green-1: #28DA4F;
  --green-2: #1ED045;
  --green-3: #09BB30;
  --green-10: rgba(40, 218, 79, 0.1);
  --green-20: rgba(40, 218, 79, 0.2);
  --green-50: rgba(40, 218, 79, 0.5);

  --cyan-1: #3CD0F0;
  --cyan-2: #2EC2E2;
  --cyan-3: #1CB0D0;
  --cyan-10: rgba(60, 208, 240, 0.1);
  --cyan-20: rgba(60, 208, 240, 0.2);
  --cyan-50: rgba(60, 208, 240, 0.5);

  --blue-1: #2B55E8;
  --blue-2: #1F49DC;
  --blue-3: #0E38CB;
  --blue-10: rgba(43, 85, 232, 0.1);
  --blue-20: rgba(43, 85, 232, 0.2);
  --blue-50: rgba(43, 85, 232, 0.5);
}