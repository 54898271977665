.footer {
  position: relative;

  width: 100%;
  height: 60px;

  background-color: var(--white-2);

  display: flex;
  align-items: center;
  justify-content: center;
}