.modal {
  position: fixed;
  display: flex;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: var(--black-50);

  align-items: center;
  justify-content: center;

  transition: opacity .3s ease-in-out;

  opacity: 0;
  pointer-events: none;

  &_open {
    opacity: 1;
    pointer-events: all;
  }

  &_open > &__container {
    transform: scale(1);
  }

  &__container {
    width: 100%;
    max-width: clamp(256px, 90%, 768px);
    padding: 25px;
    background-color: var(--white-1);
    border-radius: 8px;

    display: flex;
    flex-direction: column;

    text-align: center;
    align-items: center;
    justify-content: center;

    transform: scale(0);
    transition: transform .3s ease-in-out;
    gap: clamp(10px, 5vw, 25px);
  }
}