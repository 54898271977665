.text {
    --text-color-white: var(--white-1);
    --text-color-black: var(--black-1);
    --text-color-muted: var(--gray-3);

    font-family: var(--s3ui-font-primary);
    font-size: inherit;
    font-weight: 400;
    color: inherit;

    &_code {
        display: inline-block;
        font-family: var(--font-code);

        background-color: var(--white-3);
        color: var(--text-color-black);

        line-height: 1;
        padding: 2.5px 4px;
        border-radius: 2px;
    }

    &_color {
        &_white {
            color: var(--text-color-white);
        }

        &_black {
            color: var(--text-color-black);
        }

        &_blue {
            color: var(--blue-1);
        }

        &_muted {
            color: var(--text-color-muted);
        }
    }

    &_weight {
        &_regular {
            font-weight: 400;
        }

        &_medium {
            font-weight: 500;
        }

        &_bold {
            font-weight: 700;
        }
    }
}