.header {
  position: fixed;
  width: 100%;
  backdrop-filter: blur(50px);
  background-color: var(--white-50);

  border-bottom: 1px solid var(--white-2);
  box-shadow: 1px 0 8px var(--black-10);

  &__container {
    display: flex;
    justify-content: center;

    max-width: var(--container-width);
    height: 60px;

    margin: 0 auto;
  }


  &__menu, .menu {
    display: flex;
    align-items: center;

    gap: 15px;
    height: 100%;
    &__item {
      font-weight: 500;
      text-decoration: none;
    }
  }
}